import { FC } from 'react'

// interface BorrowProps {
// }

const Withdraw: FC = () => {
  return (
    <>
      <div className="flex flex-col bg-damgray rounded-2xl max-w-7xl gap-6 p-6 h-96">
        <div className="text-gray-400">TODO: Withdraw</div>
      </div>
    </>
  )
}

export default Withdraw
