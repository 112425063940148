import { FC } from 'react'
import './TermsAndConditionsText.css'

const TermsAndConditionsText: FC = () => {
  return (
    <div className="flex flex-col">
      <p className="font-bold self-center">DAM Terms of Service</p>
      <br />
      <p>
        Please read these Terms of Service (this &ldquo;Agreement&rdquo;) carefully. Your use or access of the Site or the Services (as defined below)
        constitutes your consent to this Agreement. If you do not agree to this Agreement, and all of the terms herein, you must immediately discontinue using
        the Site and the Services. Your continued use of the Site and Services is your ongoing acceptance of this Agreement.
      </p>
      <br />
      <p>
        This Agreement is between you (the &ldquo;User&rdquo; or &ldquo;You&rdquo;, and collectively with others using the Site, &ldquo;Users&rdquo;) and
        Address Labs Inc, a company incorporated and registered in Florida, United States (&ldquo;Company&rdquo; or &ldquo;we,&rdquo; &ldquo;our&rdquo; or
        &ldquo;us&rdquo; and together with you, the &ldquo;Parties&rdquo;) concerning your use of (including any access to) Company&rsquo;s websites, currently
        located at dam.finance, mobile applications, web applications, decentralized applications, smart contracts and API located at any of Company&rsquo;s
        websites (together with any materials and services available therein, and successor website(s) or application(s) thereto, the &ldquo;Site&rdquo;). This
        Agreement hereby incorporates by this reference any additional terms and conditions with respect to the Site posted by Company to the Site, or otherwise
        made available to you by Company.
      </p>
      <br />
      <p>
        IMPORTANT:&nbsp;This Agreement contains a mandatory individual Arbitration clause and class action and jury trial waivers that require the use of
        arbitration by Users on an individual basis to resolve Disputes, rather than jury trials or class actions or similar group actions. The Site and
        Services are only available to you and should only be used by you if you agree to these waivers.
      </p>
      <br />
      <p>
        By clicking or tapping any button or box marked &ldquo;accept&rdquo; or &ldquo;agree&rdquo; (or a similar term) in connection with this Agreement, or by
        accessing or using the Site or the Services (as defined below), you agree to be bound by this Agreement, a current version of which is available at the
        Site, and which may be modified from time to time at our sole discretion in accordance with Section 8 below.
      </p>
      <br />
      <p>1. Use of Services</p>
      <br />
      <ol>
        <li>
          <p>Services</p>
        </li>
      </ol>
      <br />
      <p>
        The Company has developed d2O Asset Modules Finance (&rdquo;DAM&rdquo;) &ndash; an online, decentralized and autonomous environment that displays price
        information and autonomous smart contract mechanisms of digital tokens and assets (&ldquo;Digital Assets&rdquo;) &ndash; and provides the user-interface
        that guides your access to DAM; the Site is used to provide Users with access to certain decentralized finance services and products on the Moonbeam,
        Polkadot, or other blockchains through DAM (the &ldquo;Services&rdquo;). The Services may require users to pay fees, such as gas charges on the Ethereum
        network, to perform a transaction. You acknowledge and agree that the Company has no control over User transactions, User digital assets, the method or
        amount of payment required for any User to engage in any transaction, or any payments of fees for any transactions. You must ensure that you have a
        sufficient balance to complete any transaction using the Site or Services before initiating such transaction.
      </p>
      <br />
      <p>
        User affirms that the User is aware and acknowledges that Company is a noncustodial provider of software services, meaning that the Company does not
        custody, control, or manage User funds in any manner whatsoever. The Services are deployed in a decentralized environment wherein the Services can be
        autonomously and directly accessed by the Users without any involvement or actions taken by Company or any third-party.
      </p>
      <br />
      <ol start={2}>
        <li>
          <p>Your Use of Services</p>
        </li>
      </ol>
      <br />
      <p>As a condition to using the Site and Services, You represent and warrant to Company that:</p>
      <ul>
        <li>
          <p>You are eligible to enter into the Agreement and use the Services in accordance with Section 2, below.</p>
        </li>
        <li>
          <p>This Agreement is valid, binding on you, and enforceable against you.</p>
        </li>
        <li>
          <p>You will comply with all terms and conditions of this Agreement.</p>
        </li>
        <li>
          <p>You are not entering into the Agreement and using the Services in your capacity as a consumer.</p>
        </li>
        <li>
          <p>You will ensure that all information that you provide on the Site is current, complete, and accurate;</p>
        </li>
        <li>
          <p>
            You will maintain the security and confidentiality of access to your cryptocurrency wallet address via private keys, access credentials, or
            otherwise;
          </p>
        </li>
        <li>
          <p>
            You acknowledge and agree that access to the Site is provided &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis, only. You acknowledge and
            agree that from time to time the Site may be inaccessible or inoperable for any reason, including, without limitation: (i) equipment malfunctions;
            (ii) periodic maintenance procedures or repairs which Company may undertake from time to time; (iii) causes beyond the control of Company or which
            are not reasonably foreseeable by Company; (iv) disruptions and temporary or permanent unavailability of the underlying blockchain infrastructure;
            (v) unavailability of third party service providers or external partners for any reason. In such a case, you may be prevented from accessing or
            using the Services.
          </p>
        </li>
        <li>
          <p>
            You acknowledge and agree that the Site and the Services may evolve or change over time. This means Company may alter, replace, or discontinue
            (temporarily or permanently) the Services at any time in Company&rsquo;s sole discretion.
          </p>
        </li>
        <li>
          <p>
            You acknowledge and agree that the pricing information provided on the Site does not represent an offer, a solicitation of an offer, or any advice
            regarding, or recommendation to enter into, a transaction with Company. You further acknowledge and agree that you are not relying upon pricing
            information provided on the Site and will undertake reasonable independent diligence as to pricing information which may appear on the Site.
          </p>
        </li>
        <li>
          <p>
            You acknowledge and agree that the Company does not act as an agent for any of the Users, or for any party or parties who may otherwise use any
            underlying blockchain infrastructure that is involved in any of the Services.
          </p>
        </li>
        <li>
          <p>You are solely responsible for your use of the Services, including all transactions of Digital Assets you make.</p>
        </li>
        <li>
          <p>In connection with using the Services, you will only transact legally obtained Digital Assets that belong to you.</p>
        </li>
        <li>
          <p>
            You will obey all applicable domestic and international laws in connection with using the Services. You will not use the Site or the Services if the
            laws of your country, or any other applicable law, prohibits you from doing so in accordance with this Agreement. You are solely responsible for
            reporting and paying any taxes applicable to your use of the Services or any other transactions of Digital Assets or rights thereto. You are
            responsible for complying with any applicable export controls or embargoes.
          </p>
        </li>
        <li>
          <p>
            Any Digital Assets used by You in connection with the Services are either owned by You or You are validly authorized to carry out actions using such
            Digital Assets.
          </p>
        </li>
        <li>
          <p>
            You acknowledge and agree that we have no control over, or liability for, the delivery, quality, safety, legality or any other aspect of any Digital
            Assets that you may transfer to or from a third party, and that we are not responsible for ensuring that an entity with whom you transact actually
            completes the transaction or is authorized to do so. If you experience a problem with any Digital Assets purchased from or sold to a third party
            through the Services, you bear the entire risk.
          </p>
        </li>
        <li>
          <p>
            You covenant that all activity and conduct in connection with your use of the Site and Services, including any resultant transactions of the Digital
            Assets, will be in compliance with all applicable law, rules, regulations, requirements, guidelines and policies of any governmental or
            quasi-governmental body or regulatory agency, any self-regulatory organization and that you will not use the Site or Services for any illegal or
            unlawful purpose, including but not limited to any Prohibited Uses noted in Section 1.3, below. You will not violate any applicable law, including,
            without limitation, any relevant and applicable anti-money laundering and anti-terrorist financing laws and any relevant and applicable privacy and
            data collection laws.
          </p>
        </li>
        <li>
          <p>
            You acknowledge and affirm that You are not and have not been (a) the subject of economic or trade sanctions administered or enforced by any
            governmental authority (b) otherwise designated on any list of prohibited or restricted parties (including but not limited to the list maintained by
            the Office of Foreign Assets Control of the U.S. Department of the Treasury) or (c) a citizen, resident, or organized in a jurisdiction or territory
            that is the subject of comprehensive country-wide, territory-wide, or regional economic sanctions by the United States (d) placed on any excluded or
            denied persons lists by any authority. You are not a resident of any of the following countries or regions: Belarus, Burma (Myanmar), Burundi,
            Central African Republic, Congo, C&ocirc;te d&apos;Ivoire, Cuba, Iran, Iraq, Lebanon, Liberia, Libya, Mali, Nicaragua, North Korea, Somalia, South
            Sudan, Sudan, Syria, Crimea (Ukraine/Russia), Venezuela, Yemen, Zimbabwe (&ldquo;Non-Supported Countries&rdquo;).
          </p>
        </li>
        <li>
          <p>
            You do not and will not use Virtual Private Network software, proxy server, or any other privacy or anonymization tools or techniques in order to
            circumvent any restrictions that apply to the Services, especially those which restrict the geographical availability of the Services.
          </p>
        </li>
        <li>
          <p>
            You acknowledge and waive any claim against Company based on changes in law, regulatory inquiries, regulatory actions, or claims that limit the
            Company&rsquo;s ability to provide access to the Site or Services. You acknowledge that at any time, your access to your cryptocurrency assets may
            be suspended or terminated, or there may be a delay in your access or use of your cryptocurrency assets which may result in the cryptocurrency
            assets diminishing in value or you being unable to complete a transaction;
          </p>
        </li>
        <li>
          <p>
            You acknowledge that we use data you submit using our Site through your wallet to detect, prevent and mitigate illicit or harmful activity and that
            we may share that data with blockchain analytics providers. We share this information with these service providers only so that they can help us
            promote the safety, security and integrity of the Services.
          </p>
        </li>
      </ul>
      <br />
      <ol start={3}>
        <li>
          <p>Prohibited Uses</p>
        </li>
      </ol>
      <br />
      <p>
        You may not use the Site and Services to engage in the following categories of activity (&quot;Prohibited Uses&quot;). The specific types of uses listed
        below are representative, but not exhaustive. If You are uncertain as to whether or not Your use of the Services involves a Prohibited Use or have
        questions about how these requirements apply to You, please contact us at hoco@dam.finance. By using the Site or Services, you confirm that You will not
        use the Site or Services to do any of the following:
      </p>
      <ul>
        <li>
          Unlawful Activity: Activity which would violate, or assist in violation of, any domestic or international law, statute, ordinance, or regulation,
          sanctions programs administered in any relevant country, including but not limited to the U.S. Department of Treasury&apos;s Office of Foreign Assets
          Control (&quot;OFAC&quot;), or which would involve proceeds of any unlawful activity; publish, distribute or disseminate any unlawful material or
          information;
        </li>
        <li>
          <p>
            Engage in any transactions that would violate any applicable law regarding securities, commodities, derivatives, or any applicable law, rule, or
            regulation concerning the integrity of trading markets, including but not limited to market manipulation, &ldquo;spoofing&rdquo; and &ldquo;wash
            trading.&rdquo;
          </p>
        </li>
        <li>
          <p>
            Abuse Other Users: Interfere with another individual&apos;s or entity&apos;s access to the Site or use of any Services; defame, abuse, extort,
            harass, stalk, threaten or otherwise violate or infringe the legal rights (such as, but not limited to, rights of privacy, publicity and
            intellectual property) of others; incite, threaten, facilitate, promote, or encourage hate, racial intolerance, or violent acts against others;
            harvest or otherwise collect information from the Site about others, including without limitation email addresses, without proper consent;
          </p>
        </li>
        <li>
          <p>
            Fraud or misrepresentation: Activity which operates to defraud Company, other Users, or any other person; provide any false, inaccurate, or
            misleading information, or misrepresent the truthfulness of any content on the Site or of the Services;
          </p>
        </li>
        <li>
          <p>
            Intellectual Property Infringement: Engage in transactions involving items that infringe or violate any copyright, trademark, right of publicity or
            privacy or any other proprietary right under the law, including but not limited to sales, distribution, or access to counterfeit music, movies,
            software, or other licensed materials without the appropriate authorization from the rights holder; use of Company intellectual property, name, or
            logo, including use of Company trade or service marks, without express consent from Company or in a manner that otherwise harms Company; any action
            that implies an untrue endorsement by or affiliation with Company.
          </p>
        </li>
        <li>
          <p>
            Illegal Source of Funds: Use or accessing the Site or Services to transmit or exchange Digital Assets that are the direct or indirect proceeds of
            any criminal or fraudulent activity, including terrorism or tax evasion.
          </p>
        </li>
        <li>
          <p>Other Prohibited Activities: Additionally, you confirm that you may not, nor may you assist other parties to:&nbsp;</p>
          <ul>
            <li>
              <p>attempt to disable or circumvent any security or access control mechanism of the Site or Services, where applicable;</p>
            </li>
            <li>
              <p>
                design or assist in designing cheats, exploits, hacks, modes or any other unauthorized third-party software to modify or interfere with the
                Services, with the exception of automation software and bots, provided that such automation software and bots operate in the ordinary course of
                using the Services and do not cause disruption or harm to the Services;
              </p>
            </li>
            <li>
              <p>
                institute, assist or become involved in any type of attack, including distribution of a virus, attacks upon the Services or the Site, that
                prevent access to or use of any of the above, other attempts to disrupt any of the above, gain unauthorized access to any of the above, or
                disrupt any other person&rsquo;s use or enjoyment of any of the above;
              </p>
            </li>
            <li>
              <p>attempt to, or harass, abuse, or harm of another person or entity, including our employees and service providers;</p>
            </li>
            <li>
              <p>impersonate another user or otherwise misrepresent yourself;</p>
            </li>
            <li>
              <p>
                take any action that imposes an unreasonable or disproportionately large load on our infrastructure; or detrimentally interfere with, intercept,
                or expropriate any system, data, or information.
              </p>
            </li>
            <li>
              <p>Induce or encourage any third party to engage in any conduct prohibited by this Agreement.</p>
            </li>
          </ul>
        </li>
      </ul>
      <br />
      <ol start={4}>
        <li>
          <p>Testnet</p>
        </li>
      </ol>
      <br />
      <p>
        (a)&nbsp;No Monetary Value. In your use of the Testnet, you may accumulate &ldquo;Testnet Tokens,&rdquo; such as through the Testnet faucet, which are
        not, and shall never convert to or accrue to become mainnet tokens or any other tokens or virtual assets. Testnet Tokens are virtual items with no
        monetary value. Testnet Tokens do not constitute any currency or property of any type and are not redeemable, refundable, or eligible for any fiat or
        virtual currency or anything else of value. Testnet Tokens are not transferable between users outside of the Testnet, and you may not attempt to sell,
        trade, or transfer any Testnet Tokens outside of the Testnet, or obtain any manner of credit using any Testnet Tokens. Any attempt to sell, trade, or
        transfer any Testnet Tokens outside of the Testnet will be null and void.
      </p>
      <br />
      <p>
        (b)&nbsp;Modification. The Company may, in its sole discretion, decide to delete, wipe or otherwise remove the Testnet at any time without notice,
        including, without limitation, the modification of the presence, amounts, or any other conditions applicable to the Testnet Tokens, without any
        liability to you or other Testnet users. The Company does not guarantee that Testnet Tokens will continue to be offered for a specific length of time
        and you may not rely upon the continued availability of any Testnet Tokens. If the Testnet expires, you acknowledge and agree that your access to and
        use of your Testnet Tokens will be removed, and all accrued Testnet Tokens will be deleted from the Testnet system. Testnet Tokens will not be converted
        into any future rewards offered by the Company.
      </p>
      <br />
      <ol start={5}>
        <li>
          <p>Fees</p>
        </li>
      </ol>
      <br />
      <p>
        Company does not currently charge any fees for use of Services. However, in connection with your use of the Services, you agree to bear all costs
        necessary to conduct a transaction, such as gas costs, which may be charged by others, including costs to access blockchain infrastructure incident to
        your transaction (i.e. &ldquo;network fees&rdquo;). We attempt to provide accurate costs information, but this information is highly volatile and can
        change quickly without the Company or Users necessarily being aware of these changes. The Company may charge fees for certain transaction types
        available at dam.finance/app including any of the automated trigger executions. All such fees payable to the Company will be disclosed to you. Please
        check the details of any transaction you enter into using dam.finance/app prior to authorizing that transaction for more information.
      </p>
      <br />
      <ol start={2}>
        <li>
          <p>Eligibility</p>
        </li>
      </ol>
      <br />
      <p>
        You affirm that you are of legal age to enter into this Agreement where you live and have the legal capacity to enter into this Agreement, to form a
        legally binding contract online, as well as that you are fully able and competent to enter into the terms, conditions, obligations, affirmations,
        representations and warranties set forth in this Agreement and to abide by and comply with this Agreement.
      </p>
      <br />
      <p>
        You may not access or use the Service for any purpose other than that for which we make the Service available. The Service may not be used in connection
        with any commercial endeavors except those that are specifically endorsed or approved by us.
      </p>
      <br />
      <p>
        If you are an individual accessing or using the Site on behalf of, or for the benefit of, any corporation, partnership or other entity with which you
        are associated (an &ldquo;Organization&rdquo;), then you are agreeing to this Agreement on behalf of yourself and such Organization, and you represent
        and warrant that you have the legal authority to bind such Organization to this Agreement. References to &ldquo;You&rdquo; and &ldquo;Your&rdquo; in
        this Agreement will refer to both the individual using the Site and to any such Organization.
      </p>
      <br />
      <p>
        You represent that You are legally permitted to use the service in your jurisdiction, including owning Digital Assets, and interacting with the Services
        in any way. You further represent that You are responsible for ensuring compliance with the laws of your jurisdiction and acknowledge that Company is
        not liable for Your compliance with such laws. You further represent and warrant that You will not use the Site or the Services if the laws of your
        country of residency or establishment prohibit you from doing so in accordance with this Agreement. You further represent that neither you nor your
        Organization is a resident of any of the Non-Supported Countries, or otherwise legally prohibited from accessing the Site or Services. Finally, You
        represent and warrant that you will not use the Service for any illegal activity.
      </p>
      <br />
      <ol start={3}>
        <li>
          <p>Representations, Warranties, and Risks</p>
        </li>
      </ol>
      <br />
      <p>3.1 No Representation or Warranty</p>
      <br />
      <p>
        You expressly understand and agree that your use of the Service is at your sole risk. WE MAKE AND EXPRESSLY DISCLAIM ALL REPRESENTATIONS AND WARRANTIES,
        EXPRESS, IMPLIED OR STATUTORY, AND WITH RESPECT TO THE SERVICES AND THE CODE, WHETHER PROPRIETARY OR OPEN SOURCE, WE SPECIFICALLY DO NOT REPRESENT AND
        WARRANT AND EXPRESSLY DISCLAIM ANY REPRESENTATION OR WARRANTY, EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION, ANY REPRESENTATIONS OR
        WARRANTIES OF TITLE, NONINFRINGEMENT, MERCHANTABILITY, USAGE, SECURITY, SUITABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE, OR AS TO THE WORKMANSHIP OR
        TECHNICAL CODING THEREOF, OR THE ABSENCE OF ANY DEFECTS THEREIN, WHETHER LATENT OR PATENT. WE DO NOT REPRESENT OR WARRANT THAT THE SITE, SERVICES, CODE
        AND ANY RELATED INFORMATION ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE. WE FURTHER EXPRESSLY DISCLAIM ALL REPRESENTATIONS AND WARRANTIES
        REGARDING ANY THIRD PARTY TECHNOLOGY, INCLUDING ALL BLOCKCHAIN INFRASTRUCTURE, WHICH MAY BE RELIED UPON BY USERS OF THE SERVICES OR SITE.
      </p>
      <br />
      <p>
        WE SPECIFICALLY DO NOT REPRESENT AND WARRANT AND EXPRESSLY DISCLAIM ANY REPRESENTATION OR WARRANTY, EXPRESS, IMPLIED OR STATUTORY THAT THE SERVICES WILL
        REMAIN AVAILABLE IN ANY JURISDICTION WHERE THEY ARE CURRENTLY AVAILABLE AND DO NOT REPRESENT AND WARRANT THAT THE COMPANY CAN GUARANTEE THE LEGALITY OF
        THE SERVICES IN ANY SPECIFIC JURISDICTION.
      </p>
      <br />
      <p>COMPANY IS NOT A BANK OR A REGULATED FINANCIAL INSTITUTION AND DOES NOT ENGAGE IN FRACTIONAL LENDING PRACTICES.</p>
      <br />
      <p>3.2 Disclaimer of Fiduciary Duties</p>
      <br />
      <p>
        To the fullest extent permitted by law and notwithstanding any other provision of this Agreement or any other agreement contemplated herein or
        applicable provisions of law or equity or otherwise, the parties hereto hereby agree to eliminate any and all fiduciary duties Company may have to the
        User, its affiliates, or the end Users of the Services, the Site or its content, provided that such exclusion or limitation of liability shall not
        extend to the Company&rsquo;s misappropriation of assets or funds of its users or its affiliates, or the end Users of the Services, Site or content
        provided by Company or other acts or omissions that constitute a bad faith violation of the implied contractual covenant of good faith and fair dealing.
      </p>
      <br />
      <p>3.3 Sophistication and Risk of Cryptographic Systems</p>
      <br />
      <p>
        By utilizing the Services or interacting with the Site in any way, you represent that you understand the inherent risks associated with cryptographic
        systems; and warrant that you have an understanding of the usage and intricacies of native cryptographic tokens, like Ether (ETH) and Bitcoin (BTC),
        smart contract based tokens such as those that follow the Ethereum Token Standard known as ERC-20 (
        <a href="https://github.com/ethereum/EIPs/issues/20">https://github.com/ethereum/EIPs/issues/20</a>), and blockchain-based software systems.
      </p>
      <br />
      <p>
        The Company does not own or control any of the underlying software or hardware through which blockchain networks are formed and operated. In general,
        the underlying software for blockchain networks tends to be open source such that anyone can use, copy, modify, and distribute it. By using the
        Services, you acknowledge and agree (i) that Company is not responsible for operation of the underlying software, hardware, and networks that there
        exists no guarantee of functionality, security, or availability of such software and networks; and (ii) that the underlying protocols are subject to
        sudden changes in operating rules (known as &ldquo;Forks&rdquo;), and that such Forks may materially affect the Services. It might be discretionarily
        decided not to support (or cease supporting) the Forked network entirely. You acknowledge and agree that Company assumes absolutely no responsibility
        whatsoever in respect of any underlying software protocols, whether Forked or not.
      </p>
      <br />
      <p>
        Underlying networks relied upon by the Services utilize public/private key cryptography. You alone are responsible for securing and protecting your
        private key(s) or other access credentials or hardware equipment used to access Digital Assets. Company does not have access to your private key(s). The
        Company does not take custody or control of User Digital Assets. Losing control of your private key(s) will permanently and irreversibly deny you access
        to Digital Assets on the Ethereum blockchain or other network. If your private key(s) are lost or compromised, neither Company nor any other person will
        be able to retrieve or protect your Digital Assets. Once your private key(s) is lost, you will not be able to transfer your Digital Assets to any other
        address or wallet. If this occurs, you will not be able to realize any value or utility from Digital Assets that you may hold now or in future.
      </p>
      <br />
      <p>3.4 Risk of Regulatory Actions in One or More Jurisdictions</p>
      <br />
      <p>
        The Services, Digital Assets, and underlying technology relied upon by the Site and Services, including Ethereum and other blockchain infrastructure
        could be impacted by one or more regulatory inquiries or regulatory action, which could impede or limit the ability of Company to continue to develop,
        or which could impede or limit your ability to access or use the Services or Ethereum blockchain, including access to your Digital Assets or other
        funds. The law as relates to the Site, Services, Digital Assets, Ethereum and public network blockchains remains fluid and quickly changing.
      </p>
      <br />
      <p>3.5 Risk of Weaknesses or Exploits in the Field of Cryptography</p>
      <br />
      <p>
        You acknowledge and understand that Cryptography is a progressing field. Advances in code cracking or technical advances such as the development of
        quantum computers may present risks to cryptocurrencies, Digital Assets and Services, which could result in the theft or loss of your Digital Assets or
        property. To the extent possible, the Company intends to update the protocol underlying the Services to account for any advances in cryptography and to
        incorporate additional security measures, but the Company does not guarantee or otherwise represent full security of the system. By using the Services
        or accessing the Site, you acknowledge these inherent risks.
      </p>
      <br />
      <p>3.6 Volatility of Cryptocurrency</p>
      <br />
      <p>
        You understand that Ethereum and other blockchain technologies and associated Digital Assets, currencies, or tokens are highly volatile due to many
        factors including but not limited to adoption, speculation, technology, and security risks. You also acknowledge that the cost of transacting on such
        technologies is variable and may increase at any time causing impact to any activities taking place on the Ethereum blockchain, including those
        facilitated by the Services. You acknowledge these risks, and represent that Company cannot be held liable for such fluctuations or increased costs.
      </p>
      <br />
      <p>3.7 Site Accuracy</p>
      <br />
      <p>
        Although it is intended to provide accurate and timely information on the Site, the Site or relevant tools may not always be entirely accurate, complete
        or current and may also include technical inaccuracies or typographical errors. In an effort to continue to provide you with as complete and accurate
        information as possible, information may be changed or updated from time to time without notice, including, without limitation, information regarding
        our policies. Accordingly, you should verify all information before relying on it, and all decisions based on information contained on the Site or
        relevant tools are your sole responsibility and Company shall have no liability for such decisions. Links to third-party materials (including, without
        limitation, websites) may be provided as a convenience but are not controlled by any entity. You acknowledge and agree that we are not responsible for
        any aspect of the information, content, or services contained in any third-party materials or on any third party sites accessible or linked to the Site
        or available via other relevant tools.
      </p>
      <br />
      <p>
        No representation is made as to the accuracy, completeness or appropriateness for any particular purpose of any pricing information distributed via the
        Site. Prices and pricing information may be higher or lower than prices and pricing information available on other platforms. Prices and pricing
        information distributed by the Site and the Services may be inaccurate and should not be relied upon.
      </p>
      <br />
      <p>3.8 Financial Risks</p>
      <br />
      <p>
        Use of the Services, in particular for trading Digital Assets, may carry financial risk. Digital Assets are, by their nature, highly experimental,
        risky, volatile and transactions carried through the Services are irreversible, final, and there are no refunds. You acknowledge and agree that you will
        access and use the Site and the Services at your own risk. The risk of loss in trading Digital Assets can be substantial. You should, therefore,
        carefully consider whether such trading is suitable for you in light of your circumstances and financial resources. By using the Services, you represent
        that you have been, are and will be solely responsible for making your own independent appraisal and investigations into the risks of a given
        transaction and the underlying Digital Assets. You represent that you have sufficient knowledge, market sophistication, technical ability, professional
        advice and experience to make your own evaluation of the merits and risks of any transaction conducted via the Services or any underlying Digital Asset.
        You accept all consequences of using the Services, including the risk that you may lose access to your Digital Assets indefinitely. All transaction
        decisions are made solely by you. Notwithstanding anything in this Agreement, we accept no responsibility whatsoever for, and will in no circumstances
        be, liable to you in connection with the use of the Services for performing Digital Asset transactions. Under no circumstances will the operation of all
        or any portion of the Site or the Services be deemed to create a relationship that includes the provision or tendering of business, financial, legal or
        investment advice.
      </p>
      <br />
      <p>3.9 Applicable Law and Tax</p>
      <br />
      <p>
        You are responsible for complying with applicable law. You agree that we are not responsible for determining whether or which laws may apply to your use
        of Services, including tax laws. You are solely responsible for reporting and paying any taxes arising from your use of the Site and Services.
      </p>
      <br />
      <p>
        Company must comply with applicable law. Applicable law, regulation, and executive orders may require us to, upon request by government agencies, take
        certain actions or provide information. Company will respond to compulsory legal process which requires disclosure of information.
      </p>
      <br />
      <ol start={4}>
        <li>
          <p>Indemnity and Limitation of Liability</p>
        </li>
      </ol>
      <br />
      <p>
        You agree to release and to indemnify, defend and hold harmless Company, as well as its officers, directors, employees contractors, agents, affiliates,
        subsidiaries and representatives, from and against any and all losses, liabilities, expenses, damages, costs and expenses (including attorneys&rsquo;
        fees, fees or penalties imposed by any regulatory authority and court costs) claims or actions of any kind whatsoever arising from or relating to Your
        use of the Site or Services, Your violation of any term, agreement, condition, or covenant of this Agreement, any party&rsquo;s use of the Site or
        services with your assistance or encouragement, or using any device, account or access credential, including private key, that you control, Your
        violation of any law, rule, or regulation, or the rights of any third party, any erroneous or inaccurate price data transmitted by the Site or Services,
        any vulnerabilities or exploits of the Site, Services, or any underlying technology relied upon by the Site or Services, any of Your acts or omissions
        that implicate publicity rights, defamation or invasion of privacy, any claims arising form or related to any change in law and from Weaknesses or
        Exploits in the Field of Cryptography that would affect blockchain systems, risks associated with underlying cryptographic protocols relied upon by You
        , risk of regulatory actions risk of exploits of cryptographic technology, risk of price volatility, risk of cybersecurity exploits, a lack of requisite
        technical knowledge, financial risks, risks of change of law, risks related to operations, changes in law, including tax laws, regulatory inquiries,
        regulatory actions, or claims that limit any User&rsquo;s ability to access the Services, and agree to indemnify and hold the Company harmless for such
        risks. Company reserves the right, at its own expense, to assume exclusive defense and control of any matter otherwise subject to indemnification by You
        and, in such case, you agree to cooperate with Company in the defense of such matter.
      </p>
      <br />
      <p>
        You acknowledge and agree that you assume full responsibility for Your use of the Site and Services. You acknowledge and agree that any information You
        send or receive during your use of the Site and Services may not be secure and may be intercepted or later acquired by unauthorized parties. You
        acknowledge and agree that Your use of the Site and Services, including any Smart Contracts with which you interact, are Your responsibility and are at
        Your own risk.
      </p>
      <br />
      <p>
        Recognizing such, You understand and agree that, to the fullest extent permitted by applicable law, neither Company nor any of our officers, directors,
        employees, contractors, agents, affiliates, or subsidiaries suppliers or licensors or related entities will be liable to You for any direct, indirect,
        incidental, special, consequential, punitive, exemplary or other damages of any kind, including without limitation damages for loss of profits,
        goodwill, use, data or other tangible or intangible losses or any other damages based on contract, tort, strict liability or any other theory (even if
        Company had been advised of the possibility of such damages), resulting (a) from the Site or Services; (b) the use or the inability to use the Site or
        Service; (c) unauthorized access to or alteration of Your transmissions or data or to any data in our control; (d) statements or conduct of any third
        party on the Site or Service; (e) any actions we take or fail to take as a result of communications you send to us; (f) human errors or mistakes
        relating to content on the Site or provided by the Services; (g) technical malfunctions; failures, including public utility, including electric,
        internet or telephone outages; (h) omissions, interruptions, latency, deletions or defects of any device or network, providers, or software (including,
        but not limited to, those that do not permit participation in the service); (i) any injury or damage persons or property, including to computer
        equipment; (j) inability to fully access the site or service or any other website; (k) theft, tampering, destruction, or unauthorized access to, images
        or other content of any kind; data that is processed late or incorrectly or is incomplete or lost; (l) typographical, printing or other errors, or any
        combination thereof; (m) offensive or defamatory conduct of any third party, or (n) any Site or Services downtime or lack of availability, or any other
        matter relating to the Site or Service, including any damage, loss, or injury resulting from hacking, tampering, or other unauthorized access or use of
        the Site or Services or the information contained within it.
      </p>
      <br />
      <p>
        Under no circumstances shall the Company or any of its officers, directors, employees, contractors, agents, affiliates, or subsidiaries be liable to You
        for any claims, proceedings, liabilities, obligations, damages, losses, or costs in an amount exceeding USD$100.00. This limitation of liability is an
        agreed upon contractual term and is not a penalty, and applies regardless of whether the alleged liability is based on contract, tort, negligence,
        strict liability, or any other basis, and even if we have been advised of the possibility of such liability. Some jurisdictions do not allow the
        exclusion of certain warranties or the limitation or exclusion of certain liabilities and damages. Accordingly, some of the disclaimers and limitations
        set forth in this Agreement may not apply to You. This limitation of liability shall apply to the fullest extent permitted by law. If you are a
        California resident, You waive the benefits and protections of California Civil Code &sect; 1542, which provides: &quot;[a]&nbsp;general release does
        not extend to claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the time of executing the release and
        that, if known by him or her, would have materially affected his or her settlement with the debtor or released party.
      </p>
      <br />
      <ol start={5}>
        <li>
          <p>Open Source and Proprietary Rights</p>
        </li>
      </ol>
      <br />
      <p>You may not use proprietary content from our Services unless you obtain permission from its owner or are otherwise permitted by law.&nbsp;</p>
      <br />
      <p>
        Some software used in our Services may be offered under an open source license that we will make available to you. Documentation of open source code is
        available on the DAM Github page. This documentation, including any code shown in it, is licensed under the GNU Affero General Public License version 3
        and you agree to abide by the terms of this license.
      </p>
      <br />
      <p>
        The &ldquo;dam.finance/app&rdquo; name, the DAM logo, other related trademarks (&ldquo;d2O Asset Modules&rdquo;), and the web domains and URLs are
        property of Address Labs Inc. Using our Services does not give you ownership of any proprietary intellectual property rights in our Services or the
        content you access. These terms do not grant you the right to use any branding or logos used in our Services. You may not remove, obscure, or alter any
        legal notices displayed in or along with our Services.
      </p>
      <br />
      <ol start={6}>
        <li>
          <p>Links</p>
        </li>
      </ol>
      <br />
      <p>
        The Service provides, or third parties may provide, links to other World Wide Web or accessible sites, applications or resources. You authorize the
        Company to provide certain personal data and information, including, but not limited to, email addresses, wallets addresses, and Service-related data,
        to such linked sites, applications and resources that you choose to access. Because Company has no control over such sites, applications and resources,
        you acknowledge and agree that Company is not responsible for the availability of such external sites, applications or resources, and does not endorse
        and is not responsible or liable for any content, advertising, products or other materials on or available from such sites or resources. You further
        acknowledge and agree that Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by
        or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.
      </p>
      <br />
      <ol start={7}>
        <li>
          <p>Site Management</p>
        </li>
      </ol>
      <br />
      <p>
        We reserve the right, but not the obligation, to: (1) monitor the Site for violations of this Terms; (2) take appropriate legal action against anyone
        who, in our sole discretion, violates the law or this Terms, including without limitation, reporting such user to law enforcement authorities; (3) in
        our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive
        in size or are in any way burdensome to our systems; and (4) otherwise manage the Site in a manner designed to protect our rights and property and to
        facilitate the proper functioning of the Site.
      </p>
      <br />
      <ol start={8}>
        <li>
          <p>Modification; Termination and Suspension</p>
        </li>
      </ol>
      <br />
      <p>
        This Agreement may be discretionarily modified or replaced at any time, unless stated otherwise herein. The most current version of this Agreement will
        be posted on the Site with the &ldquo;Last Revised&rdquo; date at the top of the Agreement. Any changes or modifications will be effective immediately
        upon posting the revisions to the Site. You shall be responsible for reviewing and becoming familiar with any such modifications. You waive any right
        you may have to receive specific notice of such changes or modifications. Use of the Site or the Services by you after any modification to the Agreement
        constitutes your acceptance of the Agreement as modified. If you do not agree to the Agreement in effect when you access or use the Services, you must
        stop using the Services. We may, at any time and without liability or prior notice, modify or discontinue all or part of the Site (including access to
        the Site via any third-party links). Neither party may assign or transfer any rights or obligations under this Agreement, provided that Company may
        assign this Agreement without your prior consent to any of Company&rsquo;s affiliates, or to its successors in interest of any business associated with
        the Services provided by Company. This Agreement shall be binding upon the permitted assigns or transferees of each party.
      </p>
      <br />
      <p>
        Company may terminate or suspend all or part of the Site and/or Services access immediately, without prior notice or liability, when the Company deems
        it appropriate in its sole discretion. The Company may limit the availability of the Site or Services to any person, geographic area, or jurisdiction at
        its sole discretion and may terminate any User&rsquo;s access to the Site and the Services, at any time and in the Company&rsquo;s sole discretion. The
        Company may suspend or disable Your access to the Site or Services if the Company considers it reasonable to do so, including if You breach any of the
        terms or conditions of the Agreement. Upon termination of your access, Your right to use the Services will immediately cease. The following provisions
        of the Agreement survive any termination of these Agreement: INDEMNITY; REPRESENTATIONS, WARRANTIES AND RISKS; LIMITATION ON LIABILITY; OUR PROPRIETARY
        RIGHTS; LINKS; TERMINATION; NO THIRD-PARTY BENEFICIARIES; ARBITRATION AND CLASS ACTION WAIVER; USE OF SERVICES.
      </p>
      <br />
      <ol start={9}>
        <li>
          <p>No Third-Party Beneficiaries</p>
        </li>
      </ol>
      <br />
      <p>
        You agree that, except as otherwise expressly provided in this Agreement, there shall be no express or implied third party beneficiaries to the
        Agreement.
      </p>
      <br />
      <ol start={10}>
        <li>
          <p>Disputes</p>
        </li>
      </ol>
      <br />
      <p>
        Please read the following section carefully because it requires you to arbitrate certain disputes with the Company and limits the manner in which you
        can seek relief from the Company.
      </p>
      <br />
      <p>10.1 Binding Arbitration</p>
      <br />
      <p>
        You and the Company: (a) waive your right to have any and all disputes or claims arising from or related to this Agreement or the Company (collectively,
        &ldquo;Disputes&rdquo;) resolved in a court; and (b) waive your right to a jury trial. Instead, you and the Company covenant and agree that You and the
        Company (the &ldquo;Parties&rdquo;) shall resolve Disputes through binding arbitration (which is the referral of a Dispute to one or more persons
        charged with reviewing the Dispute and making a final and binding determination to resolve it, instead of having the Dispute decided by a judge or jury
        in court). For any IP Relief Claims, you and the Company agree to submit to the personal and exclusive jurisdiction of and venue in the federal and
        state courts located in New York, New York You further agree to accept service of process by mail, and hereby waive any and all jurisdictional and venue
        defenses otherwise available.
      </p>
      <br />
      <p>10.2 No Class Arbitrations, Class Actions or Representative Actions</p>
      <br />
      <p>
        You and the Company agree that any Dispute is personal to you and the Company and that any such Dispute shall be brought exclusively in your independent
        capacity, and will be resolved solely through individual arbitration and will not be brought as a class arbitration, class action, private attorney
        general action, or any other type of representative proceeding. Neither Party agrees to class arbitration or to an arbitration in which an individual
        attempts to resolve a dispute as a representative of another individual or group of individuals. Further, you and the Company agree that a Dispute
        cannot be brought as a class, or other type of representative action, whether within or outside of arbitration, or on behalf of any other individual or
        group of individuals.
      </p>
      <br />
      <p>10.3 Governing law</p>
      <br />
      <p>
        This Agreement and the relationship between you and Company shall be governed by the laws of the State of New York without regard to conflict of law
        provisions.
      </p>
      <br />
      <ol start={11}>
        <li>
          <p>Miscellaneous</p>
        </li>
      </ol>
      <br />
      <p>
        These Terms and any policies or operating rules posted by us on the Site or in respect to the Service constitute the entire agreement and understanding
        between you and us. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision.
        These Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not
        be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a
        provision of these Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these
        Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency
        relationship created between you and us as a result of these Terms or use of the Service. You agree that these Terms will not be construed against us by
        virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms and lack of signing by the
        parties hereto to execute these Terms.
      </p>
      <br />
      <ol start={12}>
        <li>
          <p>Communications</p>
        </li>
      </ol>
      <br />
      <p>
        You agree and consent to receive disclosures and communications from us regarding our services (&ldquo;Communications&rdquo;), including, but not
        limited to:
      </p>
      <ul>
        <li>
          <p>Terms and conditions of service, and amendments thereto;</p>
        </li>
        <li>
          <p>Privacy policies and notices, and amendments thereto;</p>
        </li>
        <li>
          <p>Client agreements and receipts;</p>
        </li>
        <li>
          <p>Legal and regulatory disclosures and communications; and</p>
        </li>
        <li>
          <p>Customer service communications.</p>
        </li>
      </ul>
      <br />
      <p>
        We may provide Communications to you by email or by making them accessible on the Site or through email (including via &ldquo;hyperlinks&rdquo; provided
        online and in emails). We may always, in our sole discretion, provide you with any Communication via paper.
      </p>
      <br />
      <p>
        Visiting the Site, sending us emails, and completing online forms constitute Communications. You consent to receive Communications, and you agree that
        all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal
        requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO
        ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or
        requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or
        retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.
      </p>
      <br />
      <p>Withdrawal of Consent</p>
      <p>
        You may withdraw your consent to receive Communications under this Terms by contacting us at hoco@dam.finance We will process your request to withdraw
        your consent to receive electronic Communications in a reasonable time. After we process your request, your access and use of the Service will
        terminate.
      </p>
      <br />
      <p>Requesting Paper Copies</p>
      <p>
        You may request that we mail a paper copy of any electronic Communication by contacting us at hoco@dam.finance. We may charge you fees associated with
        processing and mailing your request. We will send a copy of the Communication to you within a reasonable timeframe.
      </p>
      <br />
      <ol start={13}>
        <li>
          <p>Contact Us</p>
        </li>
      </ol>
      <br />
      <p>
        Users with questions, complaints or claims with respect to the Services may contact us using the relevant contact information set forth above and
        at&nbsp;hoco@dam.finance.
      </p>
      <br />
    </div>
  )
}

export default TermsAndConditionsText
